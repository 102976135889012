<template>
  <default-layout>
    <vue-page-transition name="fade">
      <router-view></router-view>
    </vue-page-transition>
  </default-layout>
</template>

<script>
export default {
  name: 'AssetLayout'
}
</script>

<style lang="scss" scoped>
.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}
</style>
